/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */



(function($) {

    var windowMain = $(window);
    var $smlbp = 768;
    var $doc = $(document);
    var $bd = $('body');
    var is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    if (('ontouchstart' in window) ||
         (navigator.maxTouchPoints > 0) ||
         (navigator.msMaxTouchPoints > 0)) {
          /* browser with either Touch Events of Pointer Events
             running on touch-capable device */
             $bd.addClass('touch-capable');
    }

    if (is_firefox) {
      $bd.addClass('firefox');
    }

    var waitForFinalEvent = (function () {
      var timers = {};
      return function (callback, ms, uniqueId) {
        if (!uniqueId) {
          uniqueId = "Don't call this twice without a uniqueId";
        }
        if (timers[uniqueId]) {
          clearTimeout (timers[uniqueId]);
        }
        timers[uniqueId] = setTimeout(callback, ms);
      };
    })();

    /*
    * Set width check flag
    */

    var flag = windowMain.width() >= $smlbp ? true : false;

    console.log('flag = ' + flag);

    $(window).on('resize', function(){

        if ( windowMain.width() >= $smlbp && !flag === true ) {
          flag = true;
          waitForFinalEvent(function(){
            console.log('Resize...');
            multiScrollFunc.upandRunning();
          }, 1000, "some unique string");
          console.log('flag = ' + flag);
        } else if ( windowMain.width() < $smlbp && !flag === false ) {
          flag = false;
          console.log('flag = ' + flag);
          waitForFinalEvent(function(){
            console.log('Resize...');
            multiScrollFunc.upandRunning();
          }, 1000, "some unique string");
        }

    });

    var bLazy = new Blazy({
        src:'data-original',
        selector:'.imgblock',
        offset: 100
    });

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        navToggle();
        panelPop();
        
        if ( $('#multiscroll').length ) {
          multiScrollFunc.upandRunning();          
          console.log('multiscroll is go');
        }
        // showOverlay();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        // ajaxify.setup();
        // multiScrollFunc.upandRunning();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'gallery': {
      init: function() {
        // JavaScript to be fired on the home page
        // ajaxify.setup();
        // multiScrollFunc.upandRunning();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Home page
    'the_building': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        // multiScrollFunc.upandRunning();
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
  };

    // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

/* ========================================================================
 *
 *  ALL OF MY SCRIPTS
 *
 * ======================================================================== */

  navToggle = function() {

    var $theswitch = $('.x2');
    var $navigation = $('.nav');

    $theswitch.on('click', function(){
      $(this).toggleClass('close');
      $navigation.toggleClass('nav-expanded');
    });

  }

  panelPop = function() {

    var popper = $('.popper'),
        panel = $('.navigation_thumbnail_panel'),
        imgLink = panel.find('a.ms-link');

    popper.on('click', function(){
        panel.toggleClass('panel_open');
    });

    panel.on('DOMMouseScroll mousewheel wheel touchmove', function(e){ // stops main multiscroll panels being triggered by mousewheeling/touchmoving over the image link panel.
      e.stopPropagation();
    });

    $(document).on('keydown', panel, function(event) {
      if ( panel.is('.panel_open') ) {
        if (event.keyCode == 27) {
          panel.removeClass('panel_open');
        }
      }
    });

  }

  ajaxify = function() {

    var ajaxify = {},
        $lmbtn = $('.load-more');

    ajaxify.setup = function() {

        $lmbtn.on('click', function(e){

          e.preventDefault();
          // console.log('ajaxifying your request');

          $.ajax({
            url: url_path.ajaxurl,
            type: 'post',
            data: {
              action: 'sagejax'
            },
            success: function( result ) {
              alert( result );
            }
          });

        });

    }

    ajaxify.init = function() {
      ajaxify.setup();
    }

    return ajaxify;

  }();

  multiScrollFunc = function() {

      var multiScrollFunc = {}; // make it an object so we can stick more functions in it, innit! #LOL.
      var ms = $('#multiscroll'); // multiscroll section
      var windowMain = $(window);
      var scrollbtn = $('.scroll-cta');
      var theLot = $('html, body');
      var sections = $('.ms-section');
      var splash = $('#splash');
      var destroybtn = $('button.closescroller');
      var panel = $('.navigation_thumbnail_panel');
      var innerContent = $('.inner__content');
      var popper = $('.popper');
      var MSes = $('.ms-left, .ms-right, .ms-section, .ms-tableCell');

      multiScrollFunc.moveControl = !function() {
        
        var downBtn = $('.scroll-down');
        var upBtn = $('.scroll-up');

        downBtn.click(function(){
          ms.multiscroll.moveSectionDown();
        });

        upBtn.click(function(){
          ms.multiscroll.moveSectionUp();
        });

      }();

      multiScrollFunc.splashControl = function() {

        /****
         **
         * Activate Multiscroll.init();
         * Remove #splash scroll event listeners
         **
         ****/

        if (flag === true) {
          multiScrollFunc.init();
          splash.off();
          // console.log('initialising multiscroll');
          bLazy.revalidate();
          scrollbtn.off();
        }

      }

      multiScrollFunc.init = function() {

          /****
           **
           * Initiate MultiScroll after 1 second
           * Add active and opacity classes to ms
           * Scale Up close button
           **
           ****/

              if ( flag === true && $bd.is('.home') ) {

                splash.addClass('getouttahere');

                setTimeout(function() {

                  if ( !$bd.hasClass('msengaged') ) {
                    ms.multiscroll({
                      menu:'#thumb-menu',
                      css3: true,
                      scrollingSpeed: 700,
                      afterLoad:function(anchorLink, index) {
                        bLazy.revalidate();
                      },
                      onLeave:function(){
                        bLazy.revalidate();
                      }
                    });

                  } else {
                    ms.multiscroll.build();
                    bLazy.revalidate();
                    theLot.attr('style', 'overflow: hidden; height: 100%;');
                    // console.log('rebuild slider');
                  }

                  setTimeout(function() {
                    $bd.addClass('msengaged go');
                  }, 500);

                  bLazy.revalidate();

                  ms.addClass('active');
                  sections.addClass('op');
                  destroybtn.addClass('active');

                  popper.addClass('out');

                }, 1000); // end setTimeout

           // } else if ( flag === true && $bd.is('.the-building') ) { // BIG if else end
           } else if ( $bd.is('.the-building, .gallery, .location') ) { // BIG if else end
              multiScrollFunc.startifnotAlready();
           }

        }


      multiScrollFunc.startifnotAlready = function() {

            if ( !$bd.hasClass('msengaged') ) {

              console.log('initing on a page that isn\'t home')

              ms.multiscroll({
                menu:'#thumb-menu',
                css3: true,
                scrollingSpeed: 700,
                afterLoad:function(anchorLink, index) {
                  bLazy.revalidate();
                }
              });

            } else {
              ms.multiscroll.build();
              theLot.attr('style', 'overflow: hidden; height: 100%;');
              // console.log('rebuild slider');
            }

            setTimeout(function() {
              $bd.addClass('msengaged');
            }, 500);

            bLazy.revalidate();

            ms.addClass('active');
            sections.addClass('op');
            destroybtn.addClass('active');
            popper.addClass('out');

      }


      multiScrollFunc.destroy = function() {

          /****
           **
           * Destroy MultiScroll -- in other words, stop it and unattach all internal event listeners.
           * Remove inline overflow styles from html and body || hide panels.
           * Scale down close button || remove inline styles from '.ms-' blocks after they've faded out.
           * If window is bigger than small breakpoint -- reattach #splash scroll event listener
           * Hide Navigation Images Panel -- if open.
           **
           ****/

          ms.multiscroll.destroy();
          // $.fn.multiscroll.destroy();

          history.replaceState({}, document.title, "./");

          // $('body').removeClass (function (index, css) {
          //     return (css.match (/(^|\s)ms-\S+/g) || []).join(' ');
          // });

          theLot.removeAttr('style');
          sections.removeClass('op');
          ms.removeClass('active');
          popper.removeClass('out');
          $bd.removeClass('go');

          // if ( flag === true) {
            splash.on('DOMMouseScroll mousewheel wheel touchmove', multiScrollFunc.splashControl);
          // }

          setTimeout(function() {

            if (panel.hasClass('panel_open')) {
              panel.removeClass('panel_open');
            }

            splash.removeClass('getouttahere');

          }, 750);

      }

      multiScrollFunc.upandRunning = function() {

        // init this regardless of window size on pages
        // that aren't home -- this is incase the user resizes their screen
        if ( !$bd.is('.home') ) {
          multiScrollFunc.init();
          bLazy.revalidate();
        }

        if ( flag === true ) {

          scrollbtn.on('click', function(e){
            e.preventDefault();
            if (!ms.is('.active')) {
              multiScrollFunc.splashControl();
            }
          });

          if ( !ms.is('.active') ) {
            splash.on('DOMMouseScroll mousewheel wheel touchmove', multiScrollFunc.splashControl); // stops main multiscroll panels being triggered by mousewheeling/touchmoving over the image link panel.
          }

          destroybtn.on('click', function(){
            multiScrollFunc.destroy();
            $(this).removeClass('active');
          });

        }

        if ( flag === false ) {

          destroybtn.removeClass('active');
          splash.removeClass('getouttahere');

          // scrollbtn.on('click', function(e){
          //   e.preventDefault();
          // });

          if (ms.is('.active') && $bd.is('.home')) {
              multiScrollFunc.destroy();
              // console.log("I'm getting rid of multiscroll, because you've reduced your window size quite a bit and it would now look silly.");
          }

        }

      } // ends upandrunning function

    return multiScrollFunc;

  }();

  // resizefader = !function() {

  //   var body = document.body,
  //       timer;

  //   window.addEventListener('resize', function() {

  //         clearTimeout(timer);

  //         if(!body.classList.contains('window-resizing') && !$bd.is('touch-capable')) {
  //           body.classList.add('window-resizing')
  //         }

  //       waitForFinalEvent(function(){

  //         var timer = setTimeout(function(){
  //           body.classList.remove('window-resizing')
  //         }, 200);

  //       }, 300, "unopaque");

  //   }, false);

  // }();



  // Load Events
  $(document).ready(UTIL.loadEvents);

/* ========================================================================
 *
 *  jQuery Easing Expo
 *
 * ======================================================================== */

  jQuery.extend( jQuery.easing,
  {
    easeInOutExpo: function (x, t, b, c, d) {
      if (t==0) return b;
      if (t==d) return b+c;
      if ((t/=d/2) < 1) return c/2 * Math.pow(2, 10 * (t - 1)) + b;
      return c/2 * (-Math.pow(2, -10 * --t) + 2) + b;
    }
  });

})(jQuery); // Fully reference jQuery after this point.
